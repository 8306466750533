<template>
	<div>
		<base-header class="pb-6">
	      <div class="row align-items-center py-4">
	        <div class="col-lg-6 col-7">
	          <h6 class="h2 text-white d-inline-block mb-0"></h6>
	        </div>
	      </div>
	    </base-header>
	    <div class="container-fluid mt--6">
	      <div class="card mb-4">
	        <div class="card-header mt--4">
	          <div class="row">
	            <div class="col">
	              <h3 class="mb-0 text-center" v-if="formType == 'edit' || formType == 'detail'">{{ tt("mcr_form") }}</h3>
	              <h3 class="mb-0 text-center" v-if="formType == 'approval'">{{ tt("mcr_approval") }}</h3>
	            </div>
	          </div>
	        </div>
	        <div class="card-body">
	            <McrHeader></McrHeader>
	            <br>
	          	<tabs tabNavClasses="nav-fill flex-column flex-sm-row nav-wrapper" tabContentClasses="card shadow">
				    <tab-pane id="home">
				        <span slot="title">User Request</span>
				        
				        <p class="card-body description">
				        	<McrFormA1 v-if="mcrType == 'A1'"></McrFormA1>
				        	<McrFormA2a v-if="mcrType == 'A2a'"></McrFormA2a>
				        	<McrFormA2b v-if="mcrType == 'A2b'"></McrFormA2b>
				        	<McrFormA3 v-if="mcrType == 'A3'"></McrFormA3>
				        </p>
				    </tab-pane>

				    <tab-pane id="profile">
				        <span slot="title">Cataloguer Process</span>
				        <p class="card-body description">
				        	<McrFormB v-if="mcrType == 'A1' || mcrType == 'A2a' || mcrType == 'A2b' || mcrType == 'A3'"></McrFormB>
				        </p>
				    </tab-pane>
				</tabs>
	        </div>
	      </div>
	      
	    </div>
	</div>
</template>

<script>
	import Api from "@/helpers/api";
	import mcrApproval from '@/services/dashboard/mcrApproval.service';
	import McrHeader from "./McrHeader.vue";
	import McrFormA1 from "./McrFormA1.vue";
	import McrFormA2a from "./McrFormA2a.vue";
	import McrFormA2b from "./McrFormA2b.vue";
	import McrFormA3 from "./McrFormA3.vue";
	import McrFormB from "./McrFormB.vue";

	export default {
		components: {
	      McrHeader,
	      McrFormA1,
	      McrFormA2a,
	      McrFormA2b,
	      McrFormA3,
	      McrFormB
	    },
	    data() {
	    	return {
	    		btnApproval: {
	    			onLoading: false
	    		},
                urlApproval: '',
	    		totalItem: 0,
	    		mcr_code: this.$route.params.mcr_code,
				token: this.$route.params.token,
				formType: this.$route.params.type,
				mcrType: this.$route.params.mcr_type
	    	}
	    },
	};	
</script>